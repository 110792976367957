export function convertToCapital(camelCaseWord) {
    // Handle the case where the input is not a string or an empty string
    if (typeof camelCaseWord !== 'string' || camelCaseWord === '') {
        return camelCaseWord;
    }
    
    // Split the camelCase word into individual words
    const words = camelCaseWord.split(/(?=[A-Z])/);
    
    // Capitalize the first letter of each word and join them back
    const sentenceCaseWord = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    
    return sentenceCaseWord;
}

export const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

