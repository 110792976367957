import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Header from '../Dashboard/Header';
import ImageUpload from '../ImageUpload';
import { addEvent, updateEvent } from '../../utils/api';

const Add = ({ setIsAuthenticated, event }) => {
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [price, setPrice] = useState('');
  const [city, setCity] = useState('');
  const [date, setDate] = useState('');
  const [_id, setId] = useState(undefined);
  const [image, setImage] = useState();
  const [isActive, setIsActive] = useState(true);
  const [isAdding, setIsAdding] = useState(true);
  const [isSubmit,setIsSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [events, setEvents] = useState([]);
  const [isEventForm, setIsEventForm] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if(event?._id){
      setName(event.name);
      setLocation(event.location);
      setPrice(event.price);
      setCity(event.city);
      setIsActive(event.isActive);
      setDate(event.date?.split('T')[0] ?? '');
      setImage(event.image);
      setIsEventForm(event.type === 'event' ? true : false);
      setSelectedImage(event.image);
      setIsEdit(true);
      setId(event._id);
    }
  },[event])

  const convertToBase64 = (image) => {
    const reader = new FileReader()
  
    reader.readAsDataURL(image)
  
    reader.onload = () => {
      setImage(reader.result)
    }
  }

  const handleAdd = async e => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      !name || 
      (isEventForm && (!date || !location)) 
      || (!isEventForm && (!city))
    ) {
      return Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'All fields are required.',
        showConfirmButton: true,
      });
    }

    const form = document.forms.event;

    const id = events.length + 1;
    const newEvent = {
      price,
      name,
      location,
      city,
      date,
      image,
      isActive,
      type: isEventForm ? "event" : 'item'
    };

    if(!isEdit){
      newEvent.id = id; 
    }
    let response;
    if(isEdit){
      response = await updateEvent(_id,newEvent);
    }else{
      response = await addEvent(newEvent);
    }
    
    console.log(response)
    if(response.status === 'success'){
      events.push(newEvent);
      localStorage.setItem('events_data', JSON.stringify(events));
      // setEvents(events);
      
  
      Swal.fire({
        icon: 'success',
        title: 'Added!',
        text: `${isEventForm ? 'Event' : 'Item'} has been added on server.`,
        showConfirmButton: false,
        timer: 1500,
      });
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: `${isEventForm ? 'Event' : 'Item'} not added on server.`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
    form.reset();
    setIsAdding(false);
    setIsEdit(false);
    setIsSubmit(false);
    
  };

  return (
    <div className="row p-5">
      <Header setIsAuthenticated={setIsAuthenticated} title={'Add Event/Items'} />
        <form name='event' onSubmit={handleAdd}>
      <div className='row d-flex'>
          <div className='col-md-6'>
            <label htmlFor="Type">Type</label>
            <select id="type" name='type' value={isEventForm?'event':'item'} onChange={e => setIsEventForm(e.target.value === 'event')}>
              <option value={'event'}>Event</option>
              <option value={'item'}>Product</option>
            </select>
          </div>
          <div className='col-md-6'>
            <label htmlFor="name">{isEventForm?'Event':'Item'}</label>
            <input
              id="name"
              type="text"
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          {
            isEventForm &&
            <>
              <div className='col-md-6'>
              <label htmlFor="date">Date</label>
              <input
                id="date"
                type="date"
                name="date"
                value={date}
                onChange={e => setDate(e.target.value)}
              />
              </div>
              <div className='col-md-6'>
                <label htmlFor="location">Location</label>
                <input
                  id="location"
                  type="text"
                  name="location"
                  value={location}
                  onChange={e => setLocation(e.target.value)}
                />
              </div>
            </>
          }
          {
            !isEventForm &&
            <>
            <div className='col-md-6'>
              <label htmlFor="price">Price ($)</label>
              <input
                id="price"
                type="number"
                name="price"
                value={price}
                onChange={e => setPrice(e.target.value)}
              />
              </div>
              <div className='col-md-6'>
              <label htmlFor="city">Location</label>
              <input
                id="city"
                type="text"
                name="city"
                value={city}
                onChange={e => setCity(e.target.value)}
              />
              </div>
            </>
          }
          <div className='col-md-6'>
              <label htmlFor="isActive">Active : </label>
              <input
                id="isActive"
                type="checkbox"
                name="isActive"
                checked={isActive}
                onChange={e => setIsActive(e.target.value)}
              />
              </div>
          <div className='col-md-6'>
            <ImageUpload selectedImage={selectedImage} setSelectedImage={setSelectedImage} convertToBase64={convertToBase64} image={image}/>
          </div>
          <div style={{ marginTop: '30px' }}>
            <input type="submit" disabled={isSubmit} value={isEdit ? 'Update' : 'Add' } />
            <input
              style={{ marginLeft: '12px' }}
              className="muted-button"
              type="button"
              value="Cancel"
              onClick={() => setIsAdding(false)}
            />
          </div>
      </div>
        </form>
    </div>
  );
};

export default Add;
