import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Trash, Pen } from 'react-bootstrap-icons';
import { deleteEvent, getEvent } from '../../utils/api';
import Swal from 'sweetalert2';
const List = ({ events, setEditEvent }) => {
  const [items, setItems] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    setItems(events.filter(x => x.type === 'item'));
    setLocations(events.filter(x => x.type === 'event'));
  },[events]);

  const handleDelete = async (_id,type) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) =>  {
      if (result.isConfirmed) {
        const event = await deleteEvent(_id);
        if(event.status === 'success'){
            if(type === 'event'){
                setLocations(locations.filter(x => x._id !== _id));
            }else{
                setItems(items.filter(x => x._id !== _id));
            }
            Swal.fire({
                icon: 'success',
                title: 'Entry Deleted!',
                text: `Entry Deleted.`,
                showConfirmButton: false,
                timer: 1500,
              });
        }else{
            console.log('Edit feature not working');
        }
      }
    });
    
  }

  const handleEdit = async (_id,type) => {
    const event = await getEvent(_id);
    if(event.status === 'success'){
        setEditEvent(event.event);
    }else{
        console.log('Edit feature not working');
    }
  }

  return (
    <div className="contain-table row">
        <div className='col-md-6'>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>No.</th>
            <th>Date</th>
            <th>Name</th>
            <th>Location</th>
            <th>Image</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {locations && locations.length > 0 ? (
            locations.map((event, i) => (
              <tr key={event._id}>
                <td>{i + 1}</td>
                <td>{event.date.split('T')[0]}</td>
                <td>{event.name}</td>
                <td>{event.location}</td>
                <td style={{maxWidth:70, maxHeight:70}}><img height={100} src={event.image} /></td>
                <td className="text-center align-content-center" style={{'maxWidth':'22px'}}>
                    <Pen color="royalblue" data-id={event._id} value={event._id} onClick={e => handleEdit(event._id,event.type)} className="m-1" size={15} />
                    <Trash color="royalblue" data-id={event._id} value={event._id} onClick={e => handleDelete(event._id,event.type)} className="m-1" size={15} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>No Events</td>
            </tr>
          )}
        </tbody>
      </Table>
        </div>
        <div className='col-md-6'>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>No.</th>
            <th>Item Name</th>
            <th>Price</th>
            <th>City</th>
            <th>Image</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items && items.length > 0 ? (
            items.map((item, i) => (
              <tr key={item._id}>
                <td>{i + 1}</td>   
                <td>{item.name}</td>
                <td>{item.price}</td>
                <td>{item.city}</td>
                <td style={{maxWidth:70, maxHeight:70}}><img height={100} src={item.image} /></td>
                <td className="text-center align-content-center"  style={{'maxWidth':'22px'}}>
                    <Pen color="royalblue" value={item._id} onClick={e => handleEdit(item._id,item.type)} className="m-1" size={15} />
                    <Trash color="royalblue" value={item._id} onClick={e => handleDelete(item._id,item.type)} className="m-1" size={15} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>No Items</td>
            </tr>
          )}
        </tbody>
      </Table>
        </div>
      
      
    </div>
  );
};

export default List;
