import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Header from '../Dashboard/Header';
import ImageUpload from '../ImageUpload';
import { addEvent, getEvents } from '../../utils/api';
import Add from './Add';
import List from './List';

const Index = ({ setIsAuthenticated }) => {
  const [events, setEvents] = useState([]);
  const [editEvent, setEditEvent] = useState([]);

  const fetchEvents = async () => {
    const res = await getEvents();
    setEvents(res.events);
  }
  useEffect(() => {
    fetchEvents();
  },[editEvent]);

  useEffect(() => {
    console.log('event fetch')
  },[events])

  return (
    <div className="container-fluid">
      <Add setIsAuthenticated={setIsAuthenticated} event={editEvent}/>
      <List events={events} setEditEvent={setEditEvent}/>
    </div>
  );
};

export default Index;
