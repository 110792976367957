import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { SCREENSEQUENCE, countries } from '../../Constant';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { convertToCapital } from '../../utils/helper';

function DetailModel(props) {
    const { user } = props;
    const [group, setGroup] = useState();
    const [totalTimeSpend,setTotalTimeSpend] = useState();
    useEffect(() => {
        // console.log(SCREENSEQUENCE[user.group-1]);
        setGroup(SCREENSEQUENCE[user.group-1]);
        setTotalTimeSpend(user.timespend);
    },[user])

    if(!user)
        return <></>;

  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
    //   fullscreen="xxl-down"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            {user.userName} - Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Group {user.group}</h4>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>User Name</th>
                <th>Email</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Education</th>
                <th>Occupation</th>
                <th>Social</th>
                <th>Country</th>
                <th>Town</th>
                <th>Agree</th>
                <th>Category</th>
            </tr>
            </thead>
            <tbody>
            {
                <tr key={user._id}>
                    <td>{user.userName}</td>
                    <td>{user.email}</td>
                    <td>{user.age}</td>
                    <td>{convertToCapital(user.gender)}</td>
                    <td>{convertToCapital(user.education)}</td>
                    <td>{user.occupation}</td>
                    <td>{convertToCapital(user.social)}</td>
                    <td>{countries[user.country]} </td>
                    <td>{user.town} </td>
                    <td>{user.agreeToProvideFeedBack} </td>
                    <td>{user.category} </td>
                </tr>
            }
            </tbody>
        </Table>
        <DisplayData group={group} user={user} totalTimeSpend={totalTimeSpend} setTotalTimeSpend={setTotalTimeSpend}/>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const DisplayData = (props) => {
    const {user, totalTimeSpend, setTotalTimeSpend, group} = props;
    let timeSpend = totalTimeSpend;
    const displayText = (key,value) => {
        let text = "";
        switch (key) {
            case 'spsSocialMediaName':
            case 'sptSocialMediaName':
            case 'spwSocialMediaName':
            case 'socialMediaName':
                if(value)
                    text = 'Yes'
                else
                    text = 'No';
                break;
            case 'timespend':
            case 'spsTimespend':
            case 'sptTimespend':
            case 'spwTimespend':
            case 'upsTimespend':
            case 'uptTimespend':
            case 'upwTimespend':
            case 'uptTimespend':
            case 'uptTimespend':
                timeSpend += value;
                text = `${value} seconds`;
                break;
            default:
                text = value;
                break;
        }
        return text;
    }
    
    if(!group)
        return <p></p>;
    return(
        <Container>
            {group.map((screen, i) => {
            const crScreen = user[screen.toLowerCase()];
            // console.log(crScreen,screen.toLowerCase());
            if(!crScreen)
                return<></>
            return (
                <React.Fragment key={i}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Screen</th>
                                <th>{screen}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(crScreen).map((x, j) => (
                                <tr key={j+i}>
                                    <td>{convertToCapital(x)}</td>
                                    <td>{displayText(x,convertToCapital(crScreen[x]))}</td>
                                </tr>
                            ))}
                            </tbody>
                    </Table>
                </React.Fragment>
            );
            })}
            <Row>
                <Col>
                    Total Time Spend - {timeSpend} Seconds
                </Col>
                <Col>App Start: {user.created}</Col>
                <Col>App Close: {user.stopUseAppAt}</Col>
            </Row>
        </Container>
    )
}

export default DetailModel;