import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from '../Login';
import Dashboard from '../Dashboard';
import Event from '../Events';
import Navigation from '../Layout';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  useEffect(() => {
    setIsAuthenticated(JSON.parse(localStorage.getItem('is_authenticated')));
  }, []);

  return (
    <>
      {isAuthenticated ? (
        <BrowserRouter>
          <Navigation setIsAuthenticated={setIsAuthenticated}/>
          <Routes>
            <Route index element={<Dashboard setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="events" element={<Event setIsAuthenticated={setIsAuthenticated} />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <Login setIsAuthenticated={setIsAuthenticated} />
      )}
    </>
  );
};

export default App;
