import React, { useState } from "react";

// Define a functional component named UploadAndDisplayImage
const UploadAndDisplayImage = ({selectedImage, setSelectedImage, convertToBase64, image}) => {
  // Define a state variable to store the selected image
  // Return the JSX for rendering
  return (
    <div>
      {/* Conditionally render the selected image if it exists */}
      {selectedImage && (
        <div>
          {/* Display the selected image */}
          <img
            alt="not found"
            height={100}
            src={image ?? URL.createObjectURL(selectedImage)}
          />
          <br /> <br />
          {/* Button to remove the selected image */}
          <button onClick={() => setSelectedImage(null)}>Remove</button>
        </div>
      )}

      <br />

      {/* Input element to select an image file */}
      <label htmlFor="image">Image </label>
      <input
        type="file"
        name="myImage"
        // Event handler to capture file selection and update the state
        onChange={(event) => {
          console.log(event.target.files[0]); // Log the selected file
          setSelectedImage(event.target.files[0]); // Update the state with the selected file
          convertToBase64(event.target.files[0]);
        }}
      />
    </div>
  );
};

// Export the UploadAndDisplayImage component as default
export default UploadAndDisplayImage;