import React from 'react';
import { APP_URL } from './../Constant';

export async function getUsers(){
    const apiUrl = `${APP_URL}/api/groupWiseusers`;

    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        // Add any other headers your server expects
        }
    });

    if (response.ok) {
        console.log('FCM Token stored successfully on the server');
        // You can handle additional logic here if needed
        
    } else {
        console.error('Error storing FCM Token on the server:', response.statusText);
        // Handle error appropriately
    }
    return await response.json();
}

export async function sendNotification(data){
    const apiUrl = `${APP_URL}/api/notification`;

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        // Add any other headers your server expects
        },
        body: JSON.stringify(data),
    });

    if (response.ok) {
        console.log('FCM Token stored successfully on the server');
        // You can handle additional logic here if needed
        
    } else {
        console.error('Error storing FCM Token on the server:', response.statusText);
        // Handle error appropriately
    }
    return await response.json();
}

export async function sendEmail(data){
    const apiUrl = `${APP_URL}/api/sendEmail`;

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        // Add any other headers your server expects
        },
        body: JSON.stringify(data),
    });

    if (response.ok) {
        console.log('Email successfully on the server');
        // You can handle additional logic here if needed
        
    } else {
        console.error('Error emailing on the server:', response.statusText);
        // Handle error appropriately
    }
    return await response.json();
}

export async function addEvent(data){
    const apiUrl = `${APP_URL}/api/event`;

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        // Add any other headers your server expects
        },
        body: JSON.stringify(data),
    });

    if (response.ok) {
        console.log('Event Added successfully on the server');
        // You can handle additional logic here if needed
        
    } else {
        console.error('Error storing Event on the server:', response.statusText);
        // Handle error appropriately
    }
    return await response.json();
}

export async function getEvents(){
    const apiUrl = `${APP_URL}/api/events`;

    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        // Add any other headers your server expects
        }
    });

    if (response.ok) {
        console.log('Events fetched successfully from the server');
        // You can handle additional logic here if needed
        
    } else {
        console.error('Error while feching the data from the server:', response.statusText);
        // Handle error appropriately
    }
    return await response.json();
}

export async function getEvent(id){
    const apiUrl = `${APP_URL}/api/events/${id}`;

    const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        // Add any other headers your server expects
        }
    });

    if (response.ok) {
        console.log('Events fetched successfully from the server');
        // You can handle additional logic here if needed
        
    } else {
        console.error('Error while feching the data from the server:', response.statusText);
        // Handle error appropriately
    }
    return await response.json();
}

export async function updateEvent(_id,data){
    const apiUrl = `${APP_URL}/api/event/${_id}`;

    const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
        'Content-Type': 'application/json',
        // Add any other headers your server expects
        },
        body: JSON.stringify(data),
    });

    if (response.ok) {
        console.log('Update successfully on the server');
        // You can handle additional logic here if needed
        
    } else {
        console.error('Error while Updating on the server:', response.statusText);
        // Handle error appropriately
    }
    return await response.json();
}

export async function deleteEvent(_id){
    const apiUrl = `${APP_URL}/api/event/${_id}`;

    const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
        'Content-Type': 'application/json',
        // Add any other headers your server expects
        },
    });

    if (response.ok) {
        console.log('Delete successfully on the server');
        // You can handle additional logic here if needed
        
    } else {
        console.error('Error while deleting on the server:', response.statusText);
        // Handle error appropriately
    }
    return await response.json();
}