import React from 'react';

import Logout from '../Logout';
import { Button, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Header = ({ setIsAuthenticated, group, loading, percentage, handleBulkSendEmail, handleBulkSendNotification, title }) => {
  return (
    <header>
      <h1>{title ?? 'In Dino Dashboard'}</h1>
      <div style={{ marginTop: '30px', marginBottom: '18px' }}>
        {/* <Link to="events">Add Event/Shops</Link> */}
        {/* <Logout setIsAuthenticated={setIsAuthenticated} /> */}
        {/* <Button
          style={{ marginLeft: '12px' }}
          className="muted-button"
          onClick={handleBulkSendNotification}
          disabled={loading}
        >
          Send Notification to Group {group.replace('group','')}
        </Button>
        <Button
          style={{ marginLeft: '12px' }}
          className="muted-button"
          onClick={handleBulkSendEmail}
          disabled={loading}
        >
          Send Email to Group {group.replace('group','')}
        </Button> */}
        {loading &&
          <ProgressBar
          className='mt-2'
          animated
          now={percentage}
          label={`${percentage}% completed`} />
        }
      </div>
    </header>
  );
};

export default Header;
