import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Navbar,
    NavItem,
    NavbarToggler,
    Collapse,
    NavLink,
    Nav,
    NavbarBrand
} from 'react-bootstrap';
import Logout from '../Logout';
import Swal from 'sweetalert2';

function Navigation({setIsAuthenticated}) {

    // Collapse isOpen State
    const [isOpen, setIsOpen] = React.useState(false);
    const handleLogout = () => {
        Swal.fire({
          icon: 'question',
          title: 'Logging Out',
          text: 'Are you sure you want to log out?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
        }).then(result => {
          if (result.value) {
            Swal.fire({
              timer: 1500,
              showConfirmButton: false,
              willOpen: () => {
                Swal.showLoading();
              },
              willClose: () => {
                localStorage.setItem('is_authenticated', false);
                setIsAuthenticated(false);
              },
            });
          }
        });
      };
    return (
        <div style={{
            display: 'block', width: 550, padding: 30
        }}>
            <Navbar color="light" light expand="md">
                <NavbarBrand href="/"><button
                        style={{ marginLeft: '12px' }}
                        className="muted-button"
                        >
                            Dashboard
                        </button></NavbarBrand>
                <NavbarBrand href="/events"><button
                        style={{ marginLeft: '12px' }}
                        className="muted-button"
                        >
                            Add Event/Shops
                        </button></NavbarBrand>
                <NavbarBrand><button
                        style={{ marginLeft: '12px' }}
                        className="muted-button"
                        onClick={handleLogout}
                        >
                            Logout
                        </button></NavbarBrand>
            </Navbar>
        </div >
    );
}

export default Navigation;